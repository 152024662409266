import { z } from 'zod'
import Api from '..'
import { isNil, groupBy, map, pick } from 'lodash'
import { candidateSequenceParser } from './candidate_sequence'
import { sequenceParser, SequenceStepType } from './sequences'
import { jobParser } from './jobs'
import { departmentParser } from './departments'
import CONFIG from 'src/config'

export enum CandidateJobStatus {
  IMPORTING = 'IMPORTING',
  SEARCHING_FOR_EMAIL = 'SEARCHING_FOR_EMAIL',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  IN_SEQUENCE = 'IN_SEQUENCE',
  SCHEDULED = 'SCHEDULED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  RESPONDED = 'RESPONDED',
  BOUNCED = 'BOUNCED',
  REJECTED = 'REJECTED',
  HIRED = 'HIRED',
  CANDIDATE_WAITING_FOR_RESPONSE = 'CANDIDATE_WAITING_FOR_RESPONSE',
  WAITING_ON_CANDIDATE_TO_RESPOND = 'WAITING_ON_CANDIDATE_TO_RESPOND',
  CANDIDATE_UNRESPONSIVE = 'CANDIDATE_UNRESPONSIVE',
  SEQUENCE_PAUSED = 'SEQUENCE_PAUSED',
  SOURCED = 'SOURCED',
  SHORTLISTED = 'SHORTLISTED'
}

export enum CandidateJobStatusType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  INFO = 'INFO'
}

const candidateJobStatusDisplayParser = z.object({
  status: z.nativeEnum(CandidateJobStatus),
  type: z.nativeEnum(CandidateJobStatusType),
  title: z.string(),
  subtitle: z.string().nullable()
})

export type CandidateJobStatusDisplay = z.infer<typeof candidateJobStatusDisplayParser>

const candidateEducationParser = z.object({
  school: z.string(),
  degree: z.string().nullable(),
  majors: z.string().nullable(),
  startDate: z.coerce.date().nullable(),
  endDate: z.coerce.date().nullable(),
  // startDate: z.string().nullable(),
  // endDate: z.string().nullable(),
  linkedin: z.string().nullable(),
  website: z.string().nullable(),
  logoUrl: z.string().url().nullish()
})

export type CandidateEducation = z.infer<typeof candidateEducationParser>

export const companyInformationParser = z.object({
  name: z.string(),
  website: z.string().nullable(),
  linkedin: z.string().nullable(),
  summary: z.string().nullable(),
  headline: z.string().nullable(),
  founded: z.number().nullish(),
  latestFundingStage: z.string().nullish(),
  headCount: z.string().nullish(),
  gptSummary: z.string().nullish(),
  gptIndustry: z.string().nullish()
})

export type CompanyInformation = z.infer<typeof companyInformationParser>

const fundingStages: Record<string, string> = {
  angel: 'Angel',
  pre_seed: 'Pre-Seed',
  seed: 'Seed'
}

export function formatLatestFundingStage (stage?: string | null): string | null {
  if (isNil(stage)) {
    return null
  }

  if (stage === 'series_unknown') {
    return null
  }

  if (stage.startsWith('series_')) {
    return stage.toUpperCase().replace('SERIES_', 'Series ')
  }

  if (!isNil(fundingStages[stage])) {
    return fundingStages[stage]
  }

  return null
}

const candidateExperienceParser = z.object({
  title: z.string(),
  company: z.string(),
  startDate: z.coerce.date().nullable(),
  endDate: z.coerce.date().nullable(),
  // startDate: z.string().nullable(),
  // endDate: z.string().nullable(),
  linkedin: z.string().nullable(),
  logoUrl: z.string().url().nullish(),
  candidateSummary: z.string().nullish(),
  // website: z.string().nullable(),
  grouping: z.number().nullish(),
  companyInformation: companyInformationParser.nullable().optional(),
  founded: z.number().nullish(),
  experienceSkills: z.array(z.string()),
  companyRoleSkills: z.array(z.string()),
  experienceTags: z.array(z.string()),
  estimatedSalaryMin: z.number().nullish(),
  estimatedSalaryMax: z.number().nullish(),
  overlappingClientCompanyEmployees: z.array(z.object({
    linkedin: z.string(),
    name: z.string().nullable(),
    profilePhotoUrl: z.string().url().nullable()
  })).nullish(),
  joiningFundingStage: z.string().nullable()
})

export type CandidateExperience = z.infer<typeof candidateExperienceParser>

const candidateExperienceGroupedByCompanyParser = z.record(candidateExperienceParser.array())

export type CandidateExperienceGroupedByCompany = z.infer<
  typeof candidateExperienceGroupedByCompanyParser
>

export const candidateTagParser = z.object({
  name: z.string(),
  value: z.coerce.string()
})

export enum CandidateEmailLookupStage {
  IN_PROGRESS = 'IN_PROGRESS',
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND',
  SKIPPED = 'SKIPPED'
}

export const candidateParser = z.object({
  id: z.string().uuid(),
  orgId: z.string().uuid(),
  name: z.string(),
  location: z.string().nullable(),
  emails: z.array(z.string()).optional(),
  linkedin: z.string().nullish(),
  profileLookupStage: z.enum(['IN_PROGRESS', 'FOUND', 'NOT_FOUND']).nullable(),
  emailLookupStage: z.nativeEnum(CandidateEmailLookupStage).nullable(),
  lastCommunicatedAt: z.coerce.date().nullish(),
  awaitingResponse: z.boolean().nullish(),
  about: z.string().nullish(),
  tags: z.array(candidateTagParser).nullish(),
  mergeCandidateRemoteId: z.string().nullish()
})

export type Candidate = z.infer<typeof candidateParser>

const candidateExpandedParser = candidateParser.extend({
  educations: z.array(candidateEducationParser),
  experiences: z.array(candidateExperienceParser),
  profilePhotoUrl: z.string().url().nullish(),
  socials: z.array(z.object({
    platform: z.string(),
    handle: z.string()
  })).nullish()
})

export type CandidateExpanded = z.infer<typeof candidateExpandedParser>

export enum CandidateJobStage {
  SOURCED = 'SOURCED',
  APPLIED = 'APPLIED',
  PROSPECTING = 'PROSPECTING',
  INTERVIEWING = 'INTERVIEWING',
  COMMUNICATING = 'COMMUNICATING',
  REJECTED = 'REJECTED',
  HIRED = 'HIRED'
}

export const candidateJobStageDisplay: Record<CandidateJobStage, string> = {
  [CandidateJobStage.SOURCED]: 'Sourcing',
  [CandidateJobStage.APPLIED]: 'Applied',
  [CandidateJobStage.PROSPECTING]: 'Outreach',
  [CandidateJobStage.INTERVIEWING]: 'Interviewing',
  [CandidateJobStage.COMMUNICATING]: 'Inbox',
  [CandidateJobStage.REJECTED]: 'Archived',
  [CandidateJobStage.HIRED]: 'Hired'
}

export enum CandidateJobSource {
  DATA_WAREHOUSE = 'DATA_WAREHOUSE'
}

export const candidateJobSources: Partial<Record<CandidateJobSource, string>> = {
  [CandidateJobSource.DATA_WAREHOUSE]: 'Pin'
}

export function getCandidateJobSourceDisplay (source?: CandidateJobSource): string {
  if (isNil(source)) {
    return 'Unknown'
  }

  return candidateJobSources[source] ?? source
}

export enum CandidateJobCurrentStatus {
  CANDIDATE_WAITING_FOR_RESPONSE = 'CANDIDATE_WAITING_FOR_RESPONSE',
  WAITING_FOR_CANDIDATE_RESPONSE = 'WAITING_FOR_CANDIDATE_RESPONSE',
  CANDIDATE_NOT_RESPONDING = 'CANDIDATE_NOT_RESPONDING',
  NOT_YET_CONTACTED = 'NOT_YET_CONTACTED',
  IN_SEQUENCE = 'IN_SEQUENCE',
  PAUSED_SEQUENCE = 'PAUSED_SEQUENCE',
  REJECTED = 'REJECTED',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  EMAIL_BOUNCED = 'EMAIL_BOUNCED'
}

export enum CandidateJobRejectionReason {
  UNRESPONSIVE = 'UNRESPONSIVE',
  AUTO_UNRESPONSIVE = 'AUTO_UNRESPONSIVE',
  UNREACHABLE = 'UNREACHABLE',

  NOT_INTERESTED = 'NOT_INTERESTED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NOT_QUALIFIED = 'NOT_QUALIFIED',
  CULTURE_FIT = 'CULTURE_FIT',
  REJECTED_OFFER = 'REJECTED_OFFER',
  PREFERRED_ANOTHER_CANDIDATE = 'PREFERRED_ANOTHER_CANDIDATE',

  TIMING = 'TIMING',
  LACKING_EXPERIENCE = 'LACKING_EXPERIENCE',
  TOO_MUCH_EXPERIENCE = 'TOO_MUCH_EXPERIENCE',
  WRONG_LOCATION = 'WRONG_LOCATION',
  MISSING_SKILLS = 'MISSING_SKILLS',
  UNAFFORDABLE = 'UNAFFORDABLE',
  MISMATCHED_COMPANY_SIZE = 'MISMATCHED_COMPANY_SIZE',
  MISMATCHED_ROLE = 'MISMATCHED_ROLE',
  MISMATCHED_INDUSTRY = 'MISMATCHED_INDUSTRY',

  MISSING_EMAIL = 'MISSING_EMAIL',
  ALREADY_CONTACTED = 'ALREADY_CONTACTED',

  SPAM = 'SPAM',
  DUPLICATE = 'DUPLICATE',
  JOB_ARCHIVED = 'JOB_ARCHIVED',
  OTHER = 'OTHER'
}

const rejectionReasons: Record<CandidateJobRejectionReason, string> = {
  [CandidateJobRejectionReason.UNRESPONSIVE]: 'Unresponsive',
  [CandidateJobRejectionReason.AUTO_UNRESPONSIVE]: 'Unresponsive',
  [CandidateJobRejectionReason.UNREACHABLE]: 'Unreachable',

  [CandidateJobRejectionReason.NOT_INTERESTED]: 'Not Interested',
  [CandidateJobRejectionReason.NOT_AVAILABLE]: 'Not Available',
  [CandidateJobRejectionReason.NOT_QUALIFIED]: 'Unqualified',
  [CandidateJobRejectionReason.CULTURE_FIT]: 'Bad Culture Fit',
  [CandidateJobRejectionReason.REJECTED_OFFER]: 'Candidate Rejected Offer',
  [CandidateJobRejectionReason.PREFERRED_ANOTHER_CANDIDATE]: 'Preferred Another Candidate',

  [CandidateJobRejectionReason.TIMING]: 'Bad Timing',
  [CandidateJobRejectionReason.LACKING_EXPERIENCE]: 'Lacking Experience',
  [CandidateJobRejectionReason.TOO_MUCH_EXPERIENCE]: 'Too Much Experience',
  [CandidateJobRejectionReason.WRONG_LOCATION]: 'Wrong Location',
  [CandidateJobRejectionReason.MISSING_SKILLS]: 'Missing Skills',
  [CandidateJobRejectionReason.UNAFFORDABLE]: 'Unaffordable',
  [CandidateJobRejectionReason.MISMATCHED_COMPANY_SIZE]: 'Mismatched Company Size',
  [CandidateJobRejectionReason.MISMATCHED_ROLE]: 'Mismatched Role',
  [CandidateJobRejectionReason.MISMATCHED_INDUSTRY]: 'Mismatched Industry',

  [CandidateJobRejectionReason.MISSING_EMAIL]: 'Missing Email',
  [CandidateJobRejectionReason.ALREADY_CONTACTED]: 'Already Contacted',

  [CandidateJobRejectionReason.SPAM]: 'Spam',
  [CandidateJobRejectionReason.DUPLICATE]: 'Duplicate',
  [CandidateJobRejectionReason.JOB_ARCHIVED]: 'Job Archived',
  [CandidateJobRejectionReason.OTHER]: 'Other'
}

export const sourcingRejectionReasons: Partial<Record<CandidateJobRejectionReason, string>> = pick(
  rejectionReasons,
  [
    CandidateJobRejectionReason.LACKING_EXPERIENCE,
    CandidateJobRejectionReason.TOO_MUCH_EXPERIENCE,
    CandidateJobRejectionReason.WRONG_LOCATION,
    CandidateJobRejectionReason.MISSING_SKILLS,
    CandidateJobRejectionReason.MISMATCHED_ROLE,
    CandidateJobRejectionReason.MISMATCHED_COMPANY_SIZE,
    CandidateJobRejectionReason.UNAFFORDABLE,
    CandidateJobRejectionReason.TIMING,
    CandidateJobRejectionReason.MISMATCHED_INDUSTRY,
    CandidateJobRejectionReason.OTHER
  ]
)

export const inboxRejectionReasons: Partial<Record<CandidateJobRejectionReason, string>> = pick(
  rejectionReasons,
  [
    CandidateJobRejectionReason.UNRESPONSIVE,
    CandidateJobRejectionReason.UNREACHABLE,
    CandidateJobRejectionReason.NOT_INTERESTED,
    CandidateJobRejectionReason.NOT_AVAILABLE,
    CandidateJobRejectionReason.NOT_QUALIFIED,
    CandidateJobRejectionReason.PREFERRED_ANOTHER_CANDIDATE,
    CandidateJobRejectionReason.OTHER
  ]
)

export const getCandidateRejectionReasonDisplay = (
  reason?: CandidateJobRejectionReason | null
): string => {
  if (isNil(reason)) {
    return 'No reason provided'
  }

  return rejectionReasons[reason] ?? reason
}

export const candidateJobParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  orgId: z.string().uuid(),
  jobId: z.string().uuid(),
  job: z.object({
    title: z.string(),
    department: z.object({
      id: z.string().uuid(),
      name: z.string()
    }).nullable()
  }).nullish(),
  candidateId: z.string().uuid(),
  stage: z.nativeEnum(CandidateJobStage),
  source: z.nativeEnum(CandidateJobSource).nullable(),
  rejectionReason: z.nativeEnum(CandidateJobRejectionReason).nullable(),
  favorite: z.boolean(),
  gptProfileSummary: z.string().nullish(),
  creatorUserId: z.string().uuid().nullish(),
  mergeCandidateId: z.string().nullish(),
  mergeApplicationId: z.string().nullish()
})

export type CandidateJob = z.infer<typeof candidateJobParser>

export const createCandidatesSchemaParser = candidateJobParser.extend({
  candidates: z.array(candidateJobParser.omit({ stage: true })),
  stage: z.enum(['SOURCED', 'PROSPECTING', 'INBOX'])
})

export type CreateCandidates = z.infer<typeof createCandidatesSchemaParser>

const sourcingScoresSerializer = z.object({
  criteria_matches: z.record(
    z.string().refine((key) => /^\d+$/.test(key), {
      message: 'Key must be a string representing a non-negative integer'
    }),
    z.boolean()
  ).nullish(),
  criteria_matches_array: z.array(z.object({
    id: z.number(),
    description: z.string().nullable(),
    matched: z.boolean(),
    shortName: z.string().nullish(),
    required: z.boolean().nullish(),
    type: z.string().nullish()
  })).nullish()
})

export type SourcingScores = z.infer<typeof sourcingScoresSerializer>

export const candidateJobExpandedParser = candidateJobParser.extend({
  candidate: candidateExpandedParser,
  candidateSequence: candidateSequenceParser.extend({
    sequence: z.object({
      sequenceSteps: z.array(z.object({
        type: z.nativeEnum(SequenceStepType),
        position: z.number()
      }))
    }),
    statusDisplay: candidateJobStatusDisplayParser.nullable()
  }).nullable(),
  statusDisplay: candidateJobStatusDisplayParser.nullable(),
  rejectedByUserId: z.string().uuid().nullish(),
  sourcingScores: sourcingScoresSerializer.nullable()
})

export type CandidateJobExpanded = z.infer<typeof candidateJobExpandedParser>

const candidatesSearchParser = z.object({
  jobId: z.string(),
  candidateId: z.string(),
  candidateName: z.string(),
  candidateCurrentJob: z.string().nullable(),
  candidateProfilePhotoUrl: z.string().nullish(),
  candidateJobs: z.array(candidateJobExpandedParser)
})

export type CandidatesSearch = z.infer<typeof candidatesSearchParser>

export enum IN_OUTREACH_STEPS {
  ALL_CONTACTS = 'all-contacts',
  NOT_YET_CONTACTED = 'not-yet-contacted',
  MESSAGED = 'messaged',
  IN_PROGRESS = 'in-progress',
  OPENED = 'opened',
  REPLIED = 'replied',
  SCHEDULED = 'scheduled',
  NO_RESPONSE = 'no-response'
}

export const CandidateJobSequenceFilterDisplay: Record<IN_OUTREACH_STEPS, string> = {
  [IN_OUTREACH_STEPS.ALL_CONTACTS]: 'All In Sequence',
  [IN_OUTREACH_STEPS.IN_PROGRESS]: 'In Progress',
  [IN_OUTREACH_STEPS.NOT_YET_CONTACTED]: 'Not Yet Contacted',
  [IN_OUTREACH_STEPS.NO_RESPONSE]: 'No Response',
  [IN_OUTREACH_STEPS.MESSAGED]: 'Messaged',
  [IN_OUTREACH_STEPS.OPENED]: 'Opened',
  [IN_OUTREACH_STEPS.REPLIED]: 'Replied',
  [IN_OUTREACH_STEPS.SCHEDULED]: 'Scheduled'
}

export interface FetchCandidatesSearchParams {
  stage?: CandidateJobStage
  source?: CandidateJobSource | null
  favorite?: boolean
  archived?: boolean
  errored?: boolean
  lastCommunicatedWithUserId?: string
  jobSearchRefinementId?: string | null
  hideDataWarehouseSourcedCandidates?: boolean
  inOutreachStep?: IN_OUTREACH_STEPS
}

export async function fetchCandidateJobs (
  jobId: string,
  searchParams?: FetchCandidatesSearchParams
): Promise<CandidateJobExpanded[]> {
  const { data } = await Api.get(`/jobs/${jobId}/candidate_jobs`, {
    ...searchParams,
    source: searchParams?.source === null ? 'null' : searchParams?.source
  })
  return z.array(candidateJobExpandedParser).parse(data)
}

export async function rejectCandidateJobs (
  candidateJobIds: string[],
  rejectionReason?: CandidateJobRejectionReason
): Promise<CandidateJobExpanded[]> {
  const { data } = await Api.post('/candidate_jobs/reject', null, {
    candidateJobIds,
    rejectionReason
  })

  return z.array(candidateJobExpandedParser).parse(data)
}

export async function undoStageTransition (
  candidateJobIds: string[]
): Promise<CandidateJobExpanded[]> {
  const { data } = await Api.put('/candidate_jobs/undo_stage_transition', null, {
    candidateJobIds
  })

  return z.array(candidateJobExpandedParser).parse(data)
}

export interface CreateCandidate {
  name: string
  emails: string[]
  linkedin: string
}

export async function createCandidate (
  candidate: CreateCandidate,
  jobId?: string
): Promise<CandidateExpanded> {
  const { data } = await Api.post('/candidates', { jobId }, candidate)
  return candidateExpandedParser.parse(data)
}

const updateCandidateParser = z.object({
  id: z.string().uuid(),
  name: z.string(),
  location: z.string().nullable(),
  emails: z.array(z.string()).nullable().optional()
})

export type UpdateCandidate = z.infer<typeof updateCandidateParser>

export async function updateCandidate (
  updatedCandidate: UpdateCandidate
): Promise<CandidateExpanded> {
  const { data } = await Api.put(`/candidates/${updatedCandidate.id}`, null, updatedCandidate)
  return candidateExpandedParser.parse(data)
}

const activeCandidateSequenceParser = candidateSequenceParser.extend({
  candidate: candidateParser,
  sequence: sequenceParser.extend({
    job: jobParser.extend({
      department: departmentParser.nullish()
    })
  })
})

export type ActiveCandidateSequence = z.infer<typeof activeCandidateSequenceParser>

export async function getActiveCandidateSequences (
  jobId: string,
  candidateJobIds: string[]
): Promise<ActiveCandidateSequence[]> {
  const { data } = await Api.get(`/jobs/${jobId}/sequence/active_candidate_job_sequences`, { candidateJobIds })
  return z.array(activeCandidateSequenceParser).parse(data)
}

export async function addCandidateJobsToSequence (
  jobId: string,
  candidateJobIds: string[]
): Promise<CandidateJobExpanded[]> {
  const { data } = await Api.post(`/jobs/${jobId}/sequence/candidate_jobs`, null, {
    candidateJobIds
  })
  return z.array(candidateJobExpandedParser).parse(data)
}

export async function createCandidatesByLinkedInUrl (
  linkedins: string[],
  stage: CandidateJobStage,
  jobId?: string,
  favorite?: boolean
): Promise<CandidateExpanded[]> {
  const { data } = await Api.post(
    '/candidates/bulk/linkedin',
    { jobId },
    {
      linkedins,
      stage,
      favorite
    }
  )

  return z
    .object({
      candidates: z.array(candidateExpandedParser)
    })
    .parse(data).candidates
}

export async function createCandidatesFromCsvUpload (
  csvS3Key: string,
  stage: CandidateJobStage,
  jobId?: string,
  favorite?: boolean
): Promise<CandidateExpanded[]> {
  const { data } = await Api.post(
    '/candidates/bulk/linkedin/csv',
    { jobId },
    {
      csvUpload: {
        s3Key: csvS3Key
      },
      stage,
      favorite
    }
  )

  return z
    .object({
      candidates: z.array(candidateExpandedParser)
    })
    .parse(data).candidates
}

export async function setCandidateFavoriteStatus (
  candidateJobId: string,
  favoriteStatus: boolean
): Promise<CandidateJobExpanded[]> {
  const { data } = await Api.put('/candidate_jobs/favorite', null, {
    candidateJobIds: [candidateJobId],
    favorite: favoriteStatus
  })

  return z.array(candidateJobExpandedParser).parse(data)
}

const candidateJobCountsParser = z.object({
  stages: z.record(z.nativeEnum(CandidateJobStage), z.number()),
  sequenceSteps: z.record(z.coerce.number(), z.number()),
  shortlisted: z.object({
    total: z.number(),
    sourced: z.number()
  }),
  manuallyAdded: z.number(),
  prospectingErrors: z.number(),
  total: z.object({
    total: z.number(),
    totalWithoutDataWarehouseSourcedCount: z.number()
  }),
  inbox: z.object({
    totalCommunicating: z.number(),
    totalAwaitingResponse: z.number(),
    myTotalCommunicating: z.number(),
    myTotalAwaitingResponse: z.number()
  }),
  emailStats: z.object({
    openedSum: z.number(),
    clickedSum: z.number(),
    respondedSum: z.number(),
    totalSum: z.number()
  }),
  sequenceEmailsSentByOrg: z.object({
    sumPastWeek: z.number().nullable(),
    sumToday: z.number().nullable(),
    totalSum: z.number()
  }),
  candidateOutreach: z.object({
    inSequence: z.number(),
    replied: z.number(),
    opened: z.number(),
    scheduled: z.number(),
    contacted: z.number()
  }),
  readyToSend: z.number(),
  candidateInOutreach: z.record(z.nativeEnum(IN_OUTREACH_STEPS), z.number())
})

export type CandidateJobCounts = z.infer<typeof candidateJobCountsParser>

export async function fetchCandidateJobCounts (jobId: string): Promise<CandidateJobCounts> {
  const { data } = await Api.get(`/jobs/${jobId}/candidate_jobs/counts`)
  return candidateJobCountsParser.parse(data)
}

export async function fetchCandidate (candidateId: string): Promise<CandidateExpanded> {
  const { data } = await Api.get(`/candidates/${candidateId}`)
  return candidateExpandedParser.parse(data)
}

export async function fetchCandidateJob (candidateJobId: string): Promise<CandidateJobExpanded> {
  const { data } = await Api.get(`/candidate_jobs/${candidateJobId}`)
  return candidateJobExpandedParser.parse(data)
}

export async function searchCandidates (search?: string): Promise<CandidatesSearch[]> {
  const { data } = await Api.post('/candidate_jobs/search', null, { query: search })

  const { candidateJobs } = z.object({
    candidateJobs: candidateJobExpandedParser.array()
  }).parse(data)

  const candidateJobsByCandidateId = groupBy(candidateJobs, 'candidateId')

  return map(candidateJobsByCandidateId, (candidateJobs, candidateId) => {
    const candidateJob = candidateJobs[0]

    return {
      candidateId,
      candidateName: candidateJob.candidate.name,
      candidateCurrentJob: candidateJob.candidate.experiences[0]?.company,
      candidateProfilePhotoUrl: candidateJob.candidate.profilePhotoUrl,
      candidateJobs,
      jobId: candidateJob.jobId
    }
  })
}

export async function pauseCandidateJobSequence (
  candidateJobIds: Array<CandidateJob['id']>,
  pause: boolean
): Promise<CandidateJobExpanded[]> {
  const { data } = await Api.put('/candidate_jobs/pause', null, {
    candidateJobIds,
    pause
  })

  return z.array(candidateJobExpandedParser).parse(data)
}

const moveCandidateJobsParser = z.object({
  deleted: z.array(candidateJobParser),
  rejected: z.array(candidateJobParser),
  added: z.array(candidateJobExpandedParser)
})

export type CandidateJobsMove = z.infer<typeof moveCandidateJobsParser>
export async function moveCandidatesToAnotherJob (
  candidateJobIds: Array<CandidateJob['id']>,
  destinationJobId: string,
  destinationStage: CandidateJobStage,
  rejectionReason?: CandidateJobRejectionReason
): Promise<CandidateJobsMove> {
  const { data } = await Api.post('/candidate_jobs/move', null, {
    candidateJobIds,
    destinationJobId,
    rejectionReason,
    destinationStage
  })

  return moveCandidateJobsParser.parse(data)
}

export async function setCandidatesHired (
  candidateJobIds: Array<CandidateJob['id']>
): Promise<CandidateJobExpanded[]> {
  const { data } = await Api.post('/candidate_jobs/hired', null, {
    candidateJobIds
  })

  return z.array(candidateJobExpandedParser).parse(data)
}

const suggestedResponseParser = z.object({
  subject: z.string(),
  bodyHtml: z.string(),
  sendingEmailAccountId: z.string().uuid(),
  sendingUserId: z.string().uuid(),
  sendingEmailAlias: z.string().nullable(),
  latestEmailMessageId: z.string().uuid(),
  calEventTitle: z.string().nullish(),
  calEventStart: z.coerce.date().nullish(),
  calEventEnd: z.coerce.date().nullish()
})

export type SuggestedResponse = z.infer<typeof suggestedResponseParser>

export async function getSuggestedResponseForCandidateJob ({ candidateJobId }: {
  candidateJobId: string
}): Promise<SuggestedResponse | null> {
  const { data } = await Api.get(`/candidate_jobs/${candidateJobId}/suggested_response`)
  const { suggestedResponse } = z.object({
    suggestedResponse: suggestedResponseParser.nullable()
  }).parse(data)

  return suggestedResponse
}

interface ExportCandidateJobsToCsvArgs {
  jobId: string
}

export async function exportCandidateJobsToCsv ({ jobId }: ExportCandidateJobsToCsvArgs): Promise<void> {
  try {
    const url = `${CONFIG.API_DOMAIN}/jobs/${jobId}/candidate_jobs/export`
    const response = await fetch(url)
    if (response.url) {
      window.location.href = url
    }
  } catch (error) {
    console.error('Failed to trigger CSV download:', error)
    throw new Error('Failed to trigger CSV download')
  }
}
